import React from 'react';

const PageNotFound = () => {
  return (
    <div className='d-flex justify-content-center align-items-center min-vh-100'>
      <div>
        <h1 className='text-center'>Error 404</h1>
        <h2 className='text-center'>This page doesn&apos;t exist</h2>
        <div className='d-flex justify-content-center'>
          <a href={`${process.env.REACT_APP_ROOT_PATH}`} className='btn btn-primary mx-auto'>
            Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
