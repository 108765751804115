import React from 'react';

import './AboutSection.scss';

const AboutSection = () => {
  return (
    <div className='about-section'>
      <img src='/logo-footer.svg' alt='' />
      <p>
        Plentix is a product of Evedo - a blockchain based platform consisting of B2B & B2C Marketplaces aimed at
        unifying all businesses and participants involved in organizing events.
      </p>
      <h6>© 2021 Plentix.com</h6>
    </div>
  );
};

export default AboutSection;
