import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ethers } from 'ethers';

import * as fetcher from './helpers/fetcher';

import { EventLandingPage, PageNotFound, Navigation, Home, Footer, TicketSuccess } from './components';

import { createWeb3Modal, connectWallet } from './helpers/web3Modal';

import './App.scss';

let web3Modal;
function App() {
  const [show, setShow] = useState(false);
  const [library, setLibrary] = useState(null);
  const [address, setAddress] = useState(null);
  const [provider, setProvider] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [changedAddress, setChangedAddress] = useState(null);
  const [showChangeAddressModal, setShowChangeAddressModal] = useState(false);
  const [priceUSD, setPriceUSD] = useState();

  useEffect(() => {
    web3Modal = createWeb3Modal();
    if (web3Modal.cachedProvider && !window.location.pathname.includes('logout')) {
      onConnect().catch(() => {
        resetApp();
      });
    }

    fetchPriceHandler();
  }, []);

  async function fetchPriceHandler() {
    fetchPrice();
    setInterval(fetchPrice, 30000);
  }

  async function fetchPrice() {
    fetcher
      .get('/prices/eth')
      .then((data) => {
        setPriceUSD(data.priceETH);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function onConnect() {
    const provider = await connectWallet(web3Modal);
    const address = await getAddress(provider);
    await subscribeToProviderEvents(provider);

    checkingAddress(address);

    const library = new ethers.providers.Web3Provider(provider);
    const network = await library.getNetwork();
    setLibrary(library);

    setProvider(provider);
    setAddress(address);
    setChainId(network.chainId);

    if (window.location.search.includes('?changed_acc=true') && provider) {
      const accounts = await provider.request({ method: 'eth_accounts' });
      await changedAccounts(accounts);
      return;
    }

    localStorage.setItem('address', address);

    const body = document.querySelector('body');
    body.style.background = 'white';
  }

  async function resetApp() {
    await disposeCashedProvider();

    if (window.coinbaseWalletExtension) {
      if (window.coinbaseWalletExtension._addresses.length > 0 === false) {
        window.location.reload();
      }
    } else {
      window.location.reload();
    }
  }

  async function getAddress(provider) {
    let address = '';
    if (!provider.selectedAddress && !provider.accounts) {
      const addressArray = await provider.request({ method: 'eth_accounts' });
      address = addressArray[0];
    } else {
      address = provider.selectedAddress ? provider.selectedAddress : provider.accounts[0];
    }

    return address;
  }

  async function subscribeToProviderEvents(provider) {
    if (!provider.on) {
      return;
    }

    provider.on('accountsChanged', changedAccounts);
    provider.on('chainChanged', chainChanged);
    provider.on('disconnect', resetApp);
  }

  function checkingAddress(address) {
    if (address.slice(0, 2) !== '0x') {
      throw Error('Incorrect address!');
    }
  }

  async function changedAccounts(accounts) {
    if (!accounts.length) {
      // Metamask Lock fire an empty accounts array
      await resetApp();
      return;
    }

    // This check is because walletConnect fire this event when we sign a new transaction
    const currentAddress = localStorage.getItem('address');
    if (currentAddress.toLocaleLowerCase() !== accounts[0].toLocaleLowerCase()) {
      setShowChangeAddressModal(true);
      setChangedAddress(accounts[0]);
      // localStorage.removeItem('bt');
    }
  }

  const disposeCashedProvider = async () => {
    await web3Modal.clearCachedProvider();
    localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
    localStorage.removeItem('walletconnect');
    localStorage.removeItem('address');

    await unSubscribe(provider);
  };

  const unSubscribe = async (provider) => {
    if (!provider || !provider.removeListener) {
      return;
    }

    provider.removeListener('accountsChanged', changedAccounts);
    provider.removeListener('chainChanged', chainChanged);
    provider.removeListener('disconnect', resetApp);
  };

  async function chainChanged(_chainId) {
    setChainId(parseInt(_chainId.toString(10)));
  }

  return (
    <BrowserRouter>
      <TicketSuccess show={show} setShow={setShow} />
      <Navigation onConnect={onConnect} address={address} resetApp={resetApp} />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route
          exact
          path='/events/:eventId'
          element={
            <EventLandingPage
              address={address}
              onConnect={onConnect}
              library={library}
              setShowTicketSuccess={setShow}
              priceUSD={priceUSD}
            />
          }
        />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
