import React, { useEffect, useState } from 'react';
import { EventInfo, EventCategories } from './';
import { useParams } from 'react-router-dom';
import { userInformation } from '../../constants/mockData';
import { fetchData } from '../../helpers/fetcher';
import Spinner from '../Spinner/Spinner';

import './EventLandingPage.scss';

const EventLandingPage = ({ address, onConnect, library, provider, setShowTicketSuccess, priceUSD }) => {
  const [eventData, setEventData] = useState();
  const { eventId } = useParams();

  useEffect(() => {
    fetchData(`/events/${eventId}`, setEventData);
    window.scrollTo(0, 0);
  }, []);

  if (eventData) {
    return (
      <div className='landing-page-wrap'>
        <div className='landing-page-container'>
          <div className='landing-page-image'>
            <img className='tomorrowland w-100' src={eventData.pageImage} alt='img' />
          </div>
          <div className='landing-page-content-wrapper'>
            <div className='landing-page-content'>
              <EventInfo userInformation={userInformation} eventInformation={eventData} />
              <EventCategories
                ticketCategories={eventData.categories}
                address={address}
                onConnect={onConnect}
                library={library}
                provider={provider}
                setShowTicketSuccess={setShowTicketSuccess}
                priceUSD={priceUSD}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    <Spinner dimentions='6' color='white' />;
  }
};

export default EventLandingPage;
