import React from 'react';
import { useMediaQuery } from 'react-responsive';

import './ConnectWalletButton.scss';

const ConnectWalletButton = ({ onConnect, address, resetApp, handleBackdrop }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 992px)'
  });
  const concatenateAddress = `${address?.slice(0, 7)}...${address?.slice(-4)}`;

  const mobileMenu = () => {
    if (isMobile) {
      handleBackdrop();
    }
  };

  return (
    <>
      {address ? (
        <button className='connect-button-container-disconnect'>
          <span className='address me-2'>
            {concatenateAddress}
          </span>
          <span
            className='description'
            onClick={() => {
              mobileMenu();
              resetApp();
            }}
          >
            Disconnect
          </span>
        </button>
      ) : (
        <button
          className='connect-button-container'
          onClick={() => {
            mobileMenu();
            onConnect().catch((err) => {
              console.log(err);
            });
          }}
        >
          <span>Connect Wallet</span>
        </button>
      )}
    </>
  );
};

export default ConnectWalletButton;
