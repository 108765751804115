import React from 'react';

import './EventInfo.scss';

const EventInfo = ({ eventInformation, userInformation }) => {
  const startDate = new Date(eventInformation.startDate);
  const endDate = new Date(eventInformation.endDate);
  const dateAndTimeString = `${startDate.getDate()} - ${endDate.getDate()} ${startDate.toLocaleString('en-GB', {
    month: 'short'
  })}, 
  ${1900 + startDate.getYear()} - ${startDate.getHours()}:${
    startDate.getMinutes() === 0 ? '00' : startDate.getMinutes()
  }`;
  return (
    <>
      <h1 className='event-name'>{eventInformation.name}</h1>
      <div className='userProfile'>
        <img src={userInformation.profilePicture} alt='profilePicture' />
        <span>{eventInformation.host.name}</span>
      </div>
      <div className='event-description'>
        <p className='text-header'>{eventInformation.pageTextHeader}</p>
        <p className='text-body'>{eventInformation.pageTextBody}</p>
      </div>
      <a href='/' className='learn-more-button'>
        <span>Learn more</span>
      </a>
      <section className='d-flex additional-content'>
        <div className='me-4'>
          <h5>Location</h5>
          <p>{eventInformation.location}</p>
        </div>
        <div>
          <h5>Date & time</h5>
          <p className='event-details-date'>{dateAndTimeString}</p>
        </div>
      </section>
      <hr />
    </>
  );
};

export default EventInfo;
