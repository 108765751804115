import React from 'react';

import homeHeaderBackground from '../../../img/home/home-header-background.svg';
import whiteImg from '../../../img/home/white.svg';

import './HomeHeader.scss';

const HomeHeader = () => {
  return (
    <div className='home-header-wrap' style={{ backgroundImage: `url(${homeHeaderBackground})` }}>
      <div className='home-header-content'>
        <div className='home-header-content-name'>
          <h1>Tickets are no longer only for Event access</h1>
          <p>Easy to use NFT Ticketing Solution</p>
          <a href='/'>Learn more</a>
        </div>
        <img src={whiteImg} alt='img' />
      </div>
      <div style={{ clear: 'both', display: 'block' }}></div>
    </div>
  );
};

export default HomeHeader;
