import React from 'react';

import './CategoriesSummary.scss';

const CategoriesSummary = ({ numberOfTiers, startingPrice, totalSold, total }) => {
  return (
    <section className='tickets-category'>
      <div className='categories-number-prices'>
        <div className='section'>
          <h1>{numberOfTiers}</h1>
          <p>Tiers</p>
        </div>
        <div className='vl'></div>
        <div className='section'>
          <h1>{startingPrice}</h1>
          <p>Starting price</p>
        </div>
        <div className='vl d-none d-md-block'></div>
      </div>
      <div className='categories-sold-total'>
        <div className='section'>
          <h1>{totalSold} Tickets</h1>
          <p>Sold</p>
        </div>
        <div className='vl'></div>
        <div className='section'>
          <h1>{total} Tickets</h1>
          <p>Total</p>
        </div>
      </div>
    </section>
  );
};

export default CategoriesSummary;
