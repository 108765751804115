import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import './TicketSuccess.scss'

const TicketSuccess = ({show, setShow}) => {

  
  return (
    <Modal onHide={() => setShow(false)} show={show} size='md' centered className='round-edges'>
      <Modal.Header className='d-flex justify-content-center border-0'>
        <Modal.Title id='ticket-title' className='h4 fw-bold black'>
        Thank you for purchasing!
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='bidBody pt-0'>
        <Button onClick={() => setShow(false)} className='btn w-100 p-2 mb-3'>
          Close
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default TicketSuccess;
