import React from 'react';
import { CategoryDetail } from './../';

import './CategoriesGallery.scss';

const CategoriesGallery = ({ ticketCategories, address, onConnect, library, setShowTicketSuccess, priceUSD }) => {
  return (
    <section className='categories-gallery-wrapper'>
      {ticketCategories.map((category, i) => (
        <CategoryDetail
          key={i}
          image={category.image}
          name={category.name}
          description={category.description}
          price={category.price}
          perks={category.perks}
          sold={category.sold}
          total={category.total}
          address={address}
          onConnect={onConnect}
          library={library}
          setShowTicketSuccess={setShowTicketSuccess}
          priceUSD={priceUSD}
        />
      ))}
    </section>
  );
};

export default CategoriesGallery;
