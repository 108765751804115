import React from 'react';
import { Link } from 'react-router-dom';

import './EventCard.scss';
const EventCard = ({ id, image, name, type, startDate, endDate }) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const month = start.toLocaleString('en-GB', { month: 'short' });
  return (
    <Link className='event-card-link' to={`/events/${id}`}>
      <div className='event-card-image-container'>
        <img className='event-card-img' src={image} alt='' />
        <div className='date-over-image'>
          <div className='date-over-image-center'>
            <p>
              {start.getDate()}-{end.getDate()}
            </p>
            <p>{month}</p>
          </div>
        </div>
      </div>
      <div className='event-card-description'>
        <span>{type}</span>
        <p className='name'>{name}</p>
        <p className='date'>
          {start.getDate()} - {end.getDate()} {month}
        </p>
      </div>
    </Link>
  );
};

export default EventCard;
