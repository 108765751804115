if (!process.env.REACT_APP_CHAIN_ID) {
  throw new Error(`Env variable 'REACT_APP_CHAIN_ID' is missing`);
}

if (!process.env.REACT_APP_INFURA_ID) {
  throw new Error(`Env variable 'REACT_APP_INFURA_ID' is missing`);
}

export const INFURA_ID = process.env.REACT_APP_INFURA_ID;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;

// Some networks do not need explicit RPC URLs because Ethers provides

export const NETWORKS = {
  1: { name: 'Etherium Mainnet', rpc: 'homestead', explorerURL: 'https://etherscan.io', explorerName: 'Etherscan' },
  4: {
    name: 'Rinkeby Testnet',
    rpc: 'rinkeby',
    explorerURL: 'https://rinkeby.etherscan.io',
    explorerName: 'Etherscan'
  },
  42: { name: 'Kovan Testnet', rpc: 'kovan', explorerURL: 'https://kovan.etherscan.io', explorerName: 'Etherscan' },
  56: {
    name: 'Binance Smart Chain',
    rpc: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org'
    ],
    explorerURL: 'https://bscscan.com',
    explorerName: 'Bscscan'
  },
  137: {
    name: 'Polygon Mainnet',
    rpc: 'https://polygon-rpc.com',
    explorerURL: 'https://polygonscan.com',
    explorerName: 'Polygonscan'
  },
  1337: { name: 'LocalHost Mainnet', rpc: 'http://localhost:8545' }
};
