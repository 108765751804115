import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';

import './CategoryDetail.scss';

const CategoryDetail = ({
  image,
  name,
  description,
  price,
  perks,
  sold,
  total,
  address,
  onConnect,
  library,
  setShowTicketSuccess,
  priceUSD
}) => {
  const [ballance, setBallance] = useState(0);
  const [signer, setSigner] = useState(0);
  const [soldTickets, setSoldTickets] = useState(sold);

  useEffect(() => {
    start();
  }, [library]);

  async function start() {
    if (library) {
      const signer = await library.getSigner(address);
      setSigner(signer);
      const currentBallance = await signer.getBalance();
      setBallance(ethers.utils.formatEther(currentBallance));
    }
  }

  const signIn = () => {
    onConnect().catch((err) => {
      console.log(err);
    });
  };

  const buyTicker = async () => {
    let currentPrice = price.split('ETH');
    const params = {
      // from: address,
      to: '0x8558678b8aB3f2a7f50e4F0Bf274D666b32eFF70',
      value: ethers.utils.parseUnits(currentPrice[0], 'ether').toHexString()
    };

    let tx;
    try {
      tx = await signer.sendTransaction(params);
    } catch (error) {
      if (error.message.includes('insufficient funds')) {
        alert('Insufficient funds!');
      } else {
        console.error(error);
      }
    }

    library.waitForTransaction(tx.hash).then(() => {
      setShowTicketSuccess(true);
      setSoldTickets((sold) => {
        sold--;
        return sold;
      }).catch((error) => {
        console.error(error);
      });
    });
  };

  return (
    <div className='category-card-wrapper'>
      <div className='category-image-container'>
        <img src={image} alt='' />
      </div>
      <div className='category-card-information'>
        <h3 className='category-name'>{name}</h3>
        <p className='category-description'>{description}</p>
        <ul className='category-perks'>
          {perks.map((perk, i) => (
            <li key={i}>{perk}</li>
          ))}
        </ul>
        <hr />
        <div className='category-card-footer'>
          <div className='category-price-summary'>
            <div className='category-price'>
              <p className='ticket-price-label'>Price</p>
              <p className='ticket-price'>{`${price}ETH/~$${(price*priceUSD).toFixed(2)}`}</p>
            </div>
            <div className='category-sold'>
              <p className='ticket-price-label'>Sold</p>
              <div className='category-sold-display'>
                <span>
                  {soldTickets}/{total}
                </span>
              </div>
            </div>
          </div>
          <div className='button-wrapper'>
            {address ? (
              <div className='btn' onClick={buyTicker}>
                <span>Buy now</span>
              </div>
            ) : (
              <div className='btn' onClick={signIn}>
                <span>Sign up to buy</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryDetail;
