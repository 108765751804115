import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ConnectWalletButton from './ConnectWalletButton';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './Navigation.scss';

const Navigation = ({ onConnect, address, resetApp }) => {
  const [backdrop, setBackdrop] = useState(false);

  const handleBackdrop = () => {
    if (backdrop === false) {
      setBackdrop(true);
    } else {
      setBackdrop(false);
    }
  };

  return (
    <Navbar collapseOnSelect expand='lg' bg='white' variant='white' sticky='top'>
      <Container>
        <div className='brand-container'>
          <Navbar.Brand href='/'>
            <img className='plentix-icon' src='/plentix_icon.svg' alt='' />
            <img src='/plentix_logo.svg' alt='' />
          </Navbar.Brand>
          <div className='divider'></div>
          <h6>NFT Tickets</h6>
        </div>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={handleBackdrop} />
        <Navbar.Collapse id='responsive-navbar-nav' className='d-none'>
          <Nav className='me-auto'></Nav>
          <Nav>
            <Nav.Link className='additional-info-link' href='/'>
              About
            </Nav.Link>
            <ConnectWalletButton onConnect={onConnect} address={address} resetApp={resetApp} />
          </Nav>
        </Navbar.Collapse>
        <Offcanvas show={backdrop} onHide={handleBackdrop} placement={'end'}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div>
                <img className='plentix-icon' src='/plentix_icon.svg' alt='' />
                <img src='/plentix_logo.svg' alt='' />
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav>
              <Nav.Link className='additional-info-link' href='#home'>
                About
              </Nav.Link>
              <ConnectWalletButton
                onConnect={onConnect}
                address={address}
                resetApp={resetApp}
                handleBackdrop={handleBackdrop}
              />
            </Nav>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Navigation;
