import React, { useEffect, useState } from 'react';

import { EventsGallery, AboutNftTickets, HomeHeader } from './';
import { fetchData } from '../../helpers/fetcher';

import './Home.scss';
import { Spinner } from 'react-bootstrap';

const Home = () => {
  const [events, setEvents] = useState();

  useEffect(() => {
    fetchData(`/events`, setEvents);
  }, []);

  if (events) {
    return (
      <div className='home-wrap d-flex flex-column'>
        <HomeHeader />
        <EventsGallery events={events} />
        <hr />
        <AboutNftTickets />
      </div>
    );
  } else {
    <Spinner />;
  }
};

export default Home;
