import React from 'react';

import './ReminderSection.scss';

const ReminderSection = () => {
  return (
    <div className='footer-reminder-section'>
      <div className='reminder'></div>
    </div>
  );
};

export default ReminderSection;
