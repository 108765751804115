import React from 'react';

import './AboutNftTicketsCard.scss';

const AboutNftTicketsCard = ({ image, name, text }) => {
  return (
    <div className='wrap-ticket-content d-flex flex-column'>
      <img src={image} alt='' />
      <h1 className='name'>{name}</h1>
      <p className='descriptions'>{text}</p>
    </div>
  );
};

export default AboutNftTicketsCard;
