import React, { useEffect, useState } from 'react';
import { CategoriesGallery, CategoriesSummary } from '.';

import './EventCategories.scss';

const EventCategories = ({ ticketCategories, address, onConnect, library, setShowTicketSuccess, priceUSD }) => {
  const [totalSold, setTotalSold] = useState();
  const [total, setTotal] = useState();
  const [startingPrice, setStartingPrice] = useState();

  useEffect(() => {
    const sold = ticketCategories.reduce((a, b) => a + b['sold'], 0);
    setTotalSold(sold);
    const total = ticketCategories.reduce((a, b) => a + b['total'], 0);
    setTotal(total);
    const price = ticketCategories.find((ticket) => ticket.name === 'Standard Ticket').price;
    setStartingPrice(price);
  }, []);

  return (
    <>
      <h1 className='tickets-category-name'>Collectible Tickets Categories</h1>
      <CategoriesSummary
        total={total}
        totalSold={totalSold}
        startingPrice={startingPrice}
        numberOfTiers={ticketCategories.length}
      />
      <CategoriesGallery
        ticketCategories={ticketCategories}
        address={address}
        onConnect={onConnect}
        library={library}
        setShowTicketSuccess={setShowTicketSuccess}
        priceUSD={priceUSD}
      />
    </>
  );
};

export default EventCategories;
