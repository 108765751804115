import React from 'react';
import { organizerLinks, legalLinks, helpLinks, ressourcesLinks } from './../../constants/footerLinks';
import { AboutSection, LinksSection, ReminderSection } from './';
import { PartnersRibbon } from './../';

import './Footer.scss';

const Footer = () => {
  return (
    <>
      <div className='footer-wrapper'>
        <div className='footer-container'>
          <div className='footer-main-section'>
            <AboutSection />
            <div className='links-section'>
              <LinksSection name={'For Organizers'} links={organizerLinks} />
              <LinksSection name={'Legal'} links={legalLinks} />
            </div>
            <div className='links-section'>
              <LinksSection name={'Help Center'} links={helpLinks} />
              <LinksSection name={'Resources'} links={ressourcesLinks} />
            </div>
          </div>
          <ReminderSection />
        </div>
      </div>
      <PartnersRibbon />
    </>
  );
};

export default Footer;
