import React from 'react';

import './LinksSection.scss';

const LinksSection = ({ name, links }) => {
  return (
    <ul className='link-section-name'>
      <span>{name}</span>
      {links.map((link, i) => (
        <li key={i} className='link-container'>
          <a href={link.link} rel='noopener noreferrer' target='_blank'>
            {link.icon ? <img src={link.icon} alt='' /> : <></>}
            <h6>{link.linkName}</h6>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default LinksSection;
