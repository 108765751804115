import React from 'react';

import './PartnersRibbon.scss';

const PartnersRibbon = () => {
  return (
    <div className='partner-ribbon'>
      <div className='message-container d-block d-sm-flex ms-3 ms-sm-0 my-3 my-sm-0'>
        <div></div>
        <div className='ribbon-message d-block d-sm-flex mb-3 mb-sm-0'>
          <span>Looking for partners to build your event??</span>
          <div className='ribbon-link'>
            <a href='https://alpha.evedo.co/' rel='noopener noreferrer' target='_blank'>
              Browse Evedo B2b Marketplace
              <img src='/arrow.svg' alt='' />
            </a>
          </div>
        </div>
        <img src='/evedo-ribbon.png' alt='' />
      </div>
    </div>
  );
};

export default PartnersRibbon;
