import React from 'react';

import { aboutNftTickets } from '../../../constants/aboutNftTickets';
import AboutNftTicketsCard from './AboutNftTicketsCard/AboutNftTicketsCard';

import './AboutNftTickets.scss';

const AboutNftTickets = () => {
  return (
    <section className='about-nft-tickets-wrapper'>
      <div className='about-nft-tickets-container'>
        <h3>What are NFT Tickets?</h3>
        <p className='tickets-descriptions'>
          Tickets are no longer just a way for you to enter an event. They are collectible items that can be traded or
          can give you additional benefits in the future. Organizer can use NFT tickets as a tool to serve the faithful
          community for their events.
        </p>
        <div className='about-cards-container'>
          {aboutNftTickets.map((card, i) => (
            <AboutNftTicketsCard image={card.image} key={i} name={card.name} text={card.text} />
          ))}
        </div>
        <a href='/'>Read more</a>
      </div>
    </section>
  );
};

export default AboutNftTickets;
