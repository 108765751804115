import React from 'react';
import { EventCard } from './../';

import './EventsGallery.scss';

const EventsGallery = ({ events }) => {
  return (
    <section className='events-gallery-wrapper'>
      <div className='events-gallery-container'>
        <h2>Events with NFT Tickets</h2>
        <div className='events-gallery'>
          {events.map((ev, i) => (
            <EventCard
              key={i}
              id={ev._id}
              image={ev.homePageCardImage}
              name={ev.name}
              type={ev.type}
              startDate={ev.startDate}
              endDate={ev.endDate}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default EventsGallery;
