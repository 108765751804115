import axios from 'axios';

export const fetchData = async (endpoint, setDataFunction) => {
  try {
    const result = await axios.get(`${process.env.REACT_APP_SERVER_URL}${endpoint}`);
    if (!result.status === 200) {
      throw new Error(result.data.message);
    } else {
      await setDataFunction(result.data);
    }
  } catch (error) {
    console.error(error);
  }
};

export const patch = async (endpoint, headers, body) => {
  return axios
    .patch(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, body, {
      headers
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};

export const get = async (endpoint, headers) => {
  return axios
    .get(`${process.env.REACT_APP_SERVER_URL}${endpoint}`, {
      headers
    })
    .then((res) => {
      switch (res.status) {
        case 200:
          return res.data;
        default:
          throw new Error(res.data.error);
      }
    });
};
