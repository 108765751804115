export const organizerLinks = [
  { linkName: 'Features', link: 'https://plentix.co/features' },
  { linkName: 'Pricing', link: 'https://plentix.co/pricing' },
  { linkName: 'Why Plentix', link: 'https://plentix.co/why-plentix' }
];

export const legalLinks = [
  { linkName: 'Terms & Conditions', link: 'https://plentix.co/terms-of-use' },
  { linkName: 'Privacy Policy', link: 'https://plentix.co/privacy-policy' },
  { linkName: 'Cookie Policy', link: 'https://plentix.co/cookie-policy' },
  { linkName: 'Sitemap', link: 'https://plentix.co/#' },
  { linkName: 'Covid-19 Support', link: 'https://www.evedo.co/webinars' }
];

export const helpLinks = [
  { linkName: 'info@plentix.co', link: 'mailto:info@plentix.co', icon: '/mail.svg' },
  { linkName: 'Live chat', link: 'https://plentix.co/#', icon: '/chat.svg' },
  { linkName: 'Facebook', link: 'https://www.facebook.com/plentix.co/', icon: '/fb.svg' }
];

export const ressourcesLinks = [{ linkName: 'Our Blog', link: 'https://blog.plentix.co/' }];
