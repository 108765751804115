export const aboutNftTickets = [
  {
    name: 'Collectibles',
    text: 'NFT Tickets hold value overtime and can grow if the event organizer puts some activity behind them.',
    image: '/images/collectibles.svg'
  },
  {
    name: 'Private Clubs and memberships',
    text: 'Using the NFT ticket as an access code for any private memberships the organizer has instore.',
    image: '/images/memberships.svg'
  },
  {
    name: 'Visible and verifyiable on the Blockchain',
    text: 'The blockchain is the underlying technology behind NFT. It’s a massive immutable system.',
    image: '/images/blockchain.svg'
  },
  {
    name: 'Other perks for NFT holders',
    text: 'Get more sales for your events by embedding tickets seamlessly on your website.',
    image: '/images/holders.svg'
  }
];
